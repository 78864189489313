import './App.css';
import React, {Component} from 'react';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";

const LoadingIndicator = props => {

  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&
    <div style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}>
      <br/>
      <BeatLoader size={50} />
    </div>
  );  
  
}


class App extends Component {

  constructor(props){

    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  async performSearch(url) {

    const response = await fetch(url);
    return response.text();

  }

  handleSubmit(event) {

    document.getElementById("button").disabled = true;
    event.preventDefault();
    const data = new FormData(event.target);
    const symbol = data.get('symbol'); 
    if (symbol) {
      //const url = 'http://localhost:80/symbol/' + symbol
      const url = 'https://buylowsellhigh.fyi/symbol/' + symbol
      trackPromise(
        this.performSearch(url).then(response => 
          (document.getElementById("result").innerHTML = response) &&
          (document.getElementById("button").disabled = false) 
        ).catch((error) => {
          (document.getElementById("result").innerHTML = "Too many requests...please try again in a few moments! If the issue persists, please contact me below. :)") &&
          (document.getElementById("button").disabled = false) 
        }),
      );
    } else {
      document.getElementById("result").innerHTML = ""
      document.getElementById("button").disabled = false;
    }
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title"> 🚀 Buy Low, Sell High 🚀 </h1>
        </header>
        <form onSubmit={this.handleSubmit}> 
          <input className="App-search" type="search" name="symbol" placeholder="symbol"></input>
          <button id="button">to the moon!</button>
        </form>
        <LoadingIndicator/>
        <p className="App-result" id="result"></p>
        <p className="App-disclaimer">Disclaimer: The results generated by the algorithm are not financial advice. Please do your own research!</p>
        <a className="App-contactme" href="https://forms.gle/3gvGv4KcF5qELZVS8">Contact Me</a>
      </div>
    );

  }

}

export default App;
